.settings-page {
  &.sentiment-analysis,
  &.twilio,
  &.addline {
    button.exelab-btn:not(.btn-deactivate) {
      color: white;
      border-radius: 2px;
    }
    button.exelab-btn {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
  }
  &.addline .settings-features .settings-features__info button {
    margin-top: 24px;
  }
  h4 {
    margin-bottom: 24px;
    color: var(--primaryDark);
    &.not-active {
      color: var(--secondaryDark);
    }
  }
  .free-tier {
    .tiers h4 {
      color: inherit;
    }
    .not-active {
      color: var(--secondaryDark);
    }
    .settings-features__progress {
      gap: 20px;
      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        color: #606060;
      }
    }
  }
  .exelab-card {
    h3 {
      margin-bottom: 16px;
      span.eca-badge {
        color: var(--primaryDark);
        font-weight: 400;
        background: var(--primaryLight);
        padding: 4px 12px;
        border-radius: 8px;
        margin-left: 8px;
      }
    }
  }
  .settings__renew {
    h4 {
      //margin-bottom: 0;
      margin-bottom: 40px;
    }
    h6 {
      font-weight: 300;
      margin-bottom: 40px;
    }
    h3 {
      margin: 0;
      span {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  .account {
    p {
      padding-top: 24px;
    }
    .account_infos__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 24px;
      gap: 24px;

      div {
        display: flex;
        align-items: center;
        gap: 16px;
        p {
          margin: 0;
        }
      }
    }
  }
}
