.HowToPage {
  padding-top: 20px;
  .Header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-inline: 20px;
    p {
      margin: 0;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

.Hero {
  background-image: url('./../../svg/tb-bg.svg');
  background-repeat: no-repeat;
  background-position: 50% 40px;
  text-align: center;
  padding: 50px 0;
  p {
    padding: 0 20px;
  }
}

.PropertiesListContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.PropertyItem {
  padding: 20px;
  background-color: #fff;
  text-align: left;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  h4 {
    color: #4889e4;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    margin: 20px 0 0;
  }

  h6 {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 10px;
  }
}

.tutorial_text {
  font-size: 15px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 300;
  line-height: 20px;
  color: #1c1c1c;
}

.MultipleTextImage {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .TextImage_text,
  img {
    flex: 1;
    max-width: 50%;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    padding: 40px;

    & + .TextImage {
      flex-direction: column;
    }

    .TextImage_text,
    img {
      max-width: 100%;
    }
  }
}

.TextImage {
  display: flex;
  align-items: center;

  .TextImage_text,
  img {
    flex: 1;
    max-width: 50%;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;

    & + .TextImage {
      flex-direction: column;
    }

    .TextImage_text,
    img {
      max-width: 100%;
    }
  }
}

.TextImage_text {
  padding: 0 80px;
  max-width: 50%;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  h4 {
    color: #4889e4;
  }

  span {
    font-weight: 400;
  }

  span + span {
    display: block;
    font-weight: 300;
    margin-top: 20px;
  }
}

.HowToPage_footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #000;
  padding: 40px 80px;
  position: relative;
  gap: 20px;

  span {
    color: #fff;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}

.DescriptionContainer {
  p {
    margin: 0;
  }
}

.HowToPage_thanksBanner {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  height: 20rem;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  h4 {
    padding-top: 5rem;
    color: #4889e4;
    font-weight: 500;
    line-height: 30px;
  }

  h3 {
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }

  p {
    padding-bottom: 5rem;
    text-align: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.HowToPage_contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4889e4;
  padding: 38px 100px;
  font-family: 'Lexend';

  * {
    color: #fff;
  }

  h4 {
    flex-basis: 55%;
    font-weight: 500;
    line-height: 30px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    h4,
    a {
      text-align: center;
    }
  }
}
