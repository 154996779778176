.exelab-btn {
  display: inline-block;
  padding: 8px 24px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  background-color: var(--secondaryLight);
  color: var(--secondarySuperDark);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.btn-undo {
    background-color: var(--disabled); 
  }
  &.secondary {
    background-color: transparent;
    color: var(--blueTwilio) !important;
  }
  &.attachment-from-form,
  &.future-date,
  &.multiple-files-download,
  &.bulk-delete-attachment,
  &.show-line-items,
  &.addline {
    background-color: var(--secondaryLight);
    color: white;
  }
  &.twilio {
    background-color: var(--blueTwilio);
    color: white;
  }
  &:hover {
    opacity: 0.8;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    color: white;
    background-color: var(--disabled);
  }
  &.btn-deactivate {
    background: transparent;
  }
}
.btn-twilio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  align-self: center;
  color: white;
  padding: 4px 8px;
  background: var(--primaryDark);
  border-radius: 2px;
  min-width: 85px;
}

.btn-twilio.secondary {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid var(--primaryDark);
  background: transparent;
  color: var(--primaryDark);
  padding: 6px 16px;
}

.btn-twilio.empty {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background: inherit;
  color: var(--primaryDark);
  padding: 6px 16px;
}

.btn-twilio:hover {
  opacity: 0.8;
}

button.btn-twilio[disabled] {
  cursor: not-allowed;
  background: var(--disabled);
}

button.btn-twilio[disabled]:hover {
  opacity: 1;
}
