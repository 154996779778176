// BOOTSTRAP VARIABLES OVERRIDE
$primary: #333;

@import '~bootstrap/scss/bootstrap';
@import './fonts';
@import './_variables';
@import './_typo';
// components
@import './components/_footer';
@import './components/_button';
@import './components/_card';
@import './components/_spinner';
@import './components/_settings-features';
@import './components/_addon';
@import './components/_tiers';
@import './components/_number-input';
// pages
@import './layouts/_consent-page';
@import './layouts/_subscription-page';
@import './layouts/_subscription-outcome';
@import './layouts/_settings-page';
@import './layouts/_billing-page';
@import './layouts/how-to-page';

// Reset
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--black);
}

.action {
  display: block;
  font-weight: 500;
  color: var(--secondaryDark);
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &.go {
    margin-top: 20px;
    text-align: end;
  }
}

.consent-page,
.subscription-page,
.subscription-outcome,
.settings-page,
.billing-top-container {
  padding: 20px 0;
  min-height: 500px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}

.fw-300 {
  font-weight: 300;
}

.inactive {
  color: var(--inactive);
}

.error {
  color: var(--red);
}
// bootstrap override
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-left: 20px;
  padding-right: 20px;
}
.nav-tabs,
.nav-tabs .nav-link {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid transparent;
}
ul.nav.nav-tabs {
  margin-bottom: 32px;
}
.nav-tabs {
  border-bottom: 1px solid var(--disabled);
}
.nav-tabs .nav-link {
  padding-left: 25px;
  padding-right: 25px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 3px solid var(--primaryDark);
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-bottom: 3px solid var(--primaryDark);
}

.TextImage h3 {
  font-size: clamp(14px, 2vw, 22px);
  line-height: clamp(17px, 2vw, 30px);
}

.TextImage {
  padding-bottom: clamp(30px, 2vw, 80px);
}

.alert-legacy {
  margin-top: 8px;
  background: #fff1c1;
  color: var(--Gray-0, #202020);
  padding: 8px;
  font-weight: 500;
}

.alert-legacy.big {
  padding: 20px;
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 850px;
  }
}

/* APP */
// #app {
//   position: relative;
//   min-height: 500px;
//   padding: 20px 0;
// }
