.subscription-page {
  &.sentiment-analysis,
  &.twilio,
  &.addline {
    button.exelab-btn {
      color: white;
      border-radius: 2px;
    }
  }
  &.sentiment-analysis {
    .tiers__container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 200px));
      .tiers__container__tier {
        border-radius: 8px;
        min-height: 358px;
      }
      .tiers__container__tier__block {
        margin-top: unset;
      }
      button.exelab-btn {
        margin-top: auto;
      }
    }
  }
  &.twilio {
    .tiers__container {
      grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
      .tiers__container__tier {
        border-radius: 8px;
        min-height: 358px;
      }
    }
  }
  .payment {
    margin-top: 30px;
    float: right;
  }

  .error-message {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    gap: 20px;
    margin-top: 35px;
    border-radius: 4px;
    border: 1px solid var(--Error-1, #f46);
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .subscription-page__summary {
    display: flex;
    flex-direction: column;
    .subscription-page__summary__total,
    .subscription-page__summary__tier,
    .subscription-page__summary__addon {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 20px;
      align-items: center;
    }
    .subscription-page__summary__tier,
    .subscription-page__summary__addon {
      border: 1px solid var(--disabled);
      &:not(:last-child) {
        border-bottom: none;
      }
      h6 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          align-self: flex-end;
        }
      }
    }
    .subscription-page__summary__total {
      background-color: var(--primaryLight);
      color: var(--primaryDark);
      h5,
      h4 {
        color: var(--primarySuperDark);
      }
    }
  }
}
