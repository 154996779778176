.settings-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .settings-features__progress {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    border-left: 1px solid var(--disabled);
    @media (max-width: 767.98px) {
      order: -1;
      padding-left: 0;
      border-left: none;
      border-bottom: 1px solid var(--disabled);
      padding-bottom: 24px;
    }
    button.exelab-btn {
      margin-top: 8px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    }
    .settings-features__contacts__progress {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-block: 16px;
      h2 {
        font-size: 42px;
        line-height: 50px;
        span {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
  .settings-features__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 767.98px) {
      gap: 8px;
      padding-top: 24px;
    }
    .settings-features__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      svg {
        margin-left: 8px;
      }
      span.actions {
        small {
          margin-left: 10px;
          text-decoration: underline;
          color: var(--secondarySuperDark);
          font-size: 12px;
          line-height: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
