h1 {
  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
}

h2 {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

h4 {
  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

h5 {
  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

h6 {
  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

p {
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

span {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}
