.tiers {
  header {
    display: flex;
    margin-bottom: 20px;
    h4 {
      flex-shrink: 0;
      margin-top: 6px;
    }
    svg {
      width: 200px;
      //margin-left: 20px;
    }
  }
  .tiers__container {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    &.twilio {
      grid-template-columns: repeat(auto-fill, minmax(250px, 390px));
      ul {
        display: flex;
        flex-direction: column;
        gap: 4px;

        li {
          font-size: 12px;
          line-height: 15px;
          font-weight: 300;
        }
      }
    }
    .tiers__container__tier {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      padding: 18px;
      display: flex;
      flex-direction: column;
      .tiers__container__tier__block {
        margin-top: auto;
        display: flex;
        flex-direction: column;
      }
      h5 {
        color: var(--primaryDark);
        margin-bottom: 5px;
      }
      h3 {
        margin-bottom: 20px;
        .tier-plan {
          font-size: 10px;
          line-height: 20px;
          color: white;
          background: var(--primaryDark);
          text-transform: uppercase;
          padding: 5px 10px;
          border-radius: 18px;
          margin-left: 10px;
        }
        span {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}
