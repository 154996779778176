.billing-prev-page {
  color: var(--secondaryLight);
  text-decoration: none;
  background: transparent;
  border: none;
}

.billing-top-container.sentiment-analysis button.exelab-btn,
.billing-top-container.twilio button.exelab-btn,
.billing-top-container.addline button.exelab-btn {
  color: white;
  border-radius: 2px;
}

.billing-page-title {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.billing-radios-container {
  display: inline-flex;
  flex-direction: row;
  margin-top: 3rem;
  justify-content: space-between;
  max-width: 28rem;
}

.billing-radios-component div {
  margin-right: 7rem;
}

.billing-form {
  display: flex;
  flex-direction: column;
}

.billing-input-field {
  position: relative;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  padding-right: 3rem;
}

.prefix-input {
  position: relative;
}

.billing-input-field .country-code {
  position: absolute;
  font-size: 1rem;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1.4;
  color: #212529;
  font-weight: 400;
}

.form-check {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-check label {
  margin-left: 1rem;
}

.form-check input {
  width: 1.5rem;
  height: 1.5rem;
}

.form-check-input:checked {
  background-color: var(--secondaryLight);
  border-color: var(--secondaryLight);
}

.form-select,
.form-control {
  border-radius: 0.125rem;
}

.form-control.has-country-code {
  padding: 0.375rem 2.5rem;
}

.billing-input-row {
  display: inline-flex;
  width: 100%;
}

.billing-input-row > div {
  padding-right: 3rem;
}

.billing-full-width {
  width: 100%;
}

.billing-half-width {
  width: 50%;
}

.billing-form-button {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 3rem;
}

.text-danger {
  --bs-text-opacity: 1;
  color: var(--red);
}
