:root {
  --bkg: #f9f9f9;
  --disabled: #e2e2e2;
  --primaryDark: #79aa53;
  --secondarySuperDark: #764306;
  --secondaryDark: #ea9b3f;
  --secondaryLight: #fac97f;
  --black: #4e4e4e;
  --primaryLight: #e5fdd2;
  --red: #eb5757;
  --success: #baf28e;
  --blueTwilio: #3977ce;
  --blue-light-2: #d9e5f6;
  --light-2: #f4f4f6;
  --inactive: #e2ab1c;
}
