.input-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.input-number > span {
  cursor: pointer;
  font-size: 16px;
}
.input-number .minus,
.input-number .plus {
  width: 30px;
  height: 30px;
  background: #f2f2f2;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.input-number span.minus[disabled],
.input-number span.plus[disabled] {
  background: white;
  cursor: not-allowed;
}
.input-number > input {
  height: 35px;
  width: 100px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}
