.addon {
  &.addon--settings {
    box-shadow: none;
    padding: 0;
  }
  .addon__load {
    display: flex;
    gap: 15px;
  }
  .addon__alert {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    text-align: center;
    span {
      margin-bottom: 10px;
    }
  }
  h4 {
    color: var(--primaryDark);
  }
  h6 {
    span {
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
    }
  }
  .addon__price {
    margin-top: 40px;
    display: flex;
    align-self: flex-end;
    align-items: center;

    .addon__price__value {
      display: flex;
      flex-direction: column;
      h3 {
        align-self: flex-end;
        margin: 0;
        span {
          font-size: 18px;
          line-height: 20px;
          font-weight: 500;
        }
      }
    }
    button {
      margin-left: 20px;
    }
  }
  .addon__load__wrapper_heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    h6 {
      margin-bottom: 0;
    }
    #loading {
      margin-right: 10px;
    }
  }
  .is_data_processing {
    padding: 20px;
    border: solid 1px #E2E2E2;
    max-width: 600px;
    position: relative;
  }

  .is_data_processing:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background-color: #FCB35D;
  }
}
